<template>
  <div :class="['tab-six', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-10.png" alt="" />
    </section>
    <section class="images-wrap">
      <div class="img-item" v-for="(item, index) in cards" :key="index" @click="openPopup(item, index)">
        <img :src="item.url" alt="" />
      </div>
    </section>
    <transition name="bounce">
      <Popup :visible.sync="visible" v-if="visible" :info="popupInfo[popupIndex]"></Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./Popup";
export default {
  components: {
    Popup,
  },
  data() {
    return {
      animate: false,
      visible: false,
      cards: [
        { url: require("../imgs/1@2x-1.png") },
        { url: require("../imgs/2@2x-2.png") },
        { url: require("../imgs/3@2x-3.png") },
        { url: require("../imgs/4@2x-4.png") },
      ],
      popupInfo: [
        {
          files: [
            { url: require("../imgs/card1-1.png") },
            { url: require("../imgs/card1-2.png") },
            { url: require("../imgs/card1-3.png") },
            { url: require("../imgs/card1-4.png") },
            { url: require("../imgs/card1-5.png") },
          ],
          title: "概念视觉设计",
          desc: "游戏项目前期的视觉设定非常重要，能够流畅高效的进行风格探索是每个项目组的刚需。我们有非常专业的、多样化的美术专家，能满足目前市面上绝大部分的视觉风格设计需求。",
        },
        {
          files: [
            { url: require("../imgs/card2-1.png") },
            { url: require("../imgs/card2-2.png") },
            { url: require("../imgs/card2-3.png") },
            { url: require("../imgs/card2-4.png") },
            { url: require("../imgs/card2-5.png") },
            { url: require("../imgs/card2-6.png") },
            { url: require("../imgs/card2-7.png") },
          ],
          title: "研发美术资源",
          desc: "在深度参与了大量优质项目后，我们已经具备了完整的研发美术资源产出能力。同时，在大厂各个垂直细分的需求之外，我们还成功承接了中小型研发公司的整包需求。",
        },
        {
          files: [
            { url: require("../imgs/card3-1.png") },
            { url: require("../imgs/card3-2.png") },
            { url: require("../imgs/card3-3.png") },
            { url: require("../imgs/card3-4.png") },
            { url: require("../imgs/card3-5.png") },
          ],
          title: "运营美术资源",
          desc: "经过多年积累，我们对于运营美术的需求理解和制作已经非常专业。在运营中最常见的banner、美宣KV、动特效视频、项目周边视觉设计等方面我们都有充足的涉猎。",
        },

        {
          files: [{ url: require("../imgs/card4-1.png") }],
          title: "企业人才输送",
          desc: "我们具备专业化的人才教育体系，还与很多游戏行业一线公司建立了人才输送渠道。 每年我们都有大量优秀学员被大厂录用。同时我们也有完备的人才管理模式，能提 供健全的人才成长计划。",
        },
      ],
      popupIndex: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    openPopup(item, index) {
      this.visible = true;
      this.popupIndex = index;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-six {
  height: 100%;
  overflow: hidden;
  position: relative;
  background: url("../imgs/bg5.png") repeat center;
  background-size: 150%;
  padding: 0 9vw 0 15vw;
  .background {
    &-1 {
      position: fixed;
      bottom: remPc(-250);
      right: remPc(-300);
      width: remPc(756);
      transform: rotate(-90deg);
      transform-origin: 110% 20%;
    }
  }
  .images-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img-item {
      img {
        width: 15vw;
        transition: all 0.5s;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
      &:nth-of-type(1) {
        margin-top: 12vh;
        transform: translateY(-200%);
      }
      &:nth-of-type(2) {
        margin-top: -2vh;
        transform: translateY(200%);
      }
      &:nth-of-type(3) {
        margin-top: -14vh;
        transform: translateY(-200%);
      }
      &:nth-of-type(4) {
        transform: translateY(200%);
        margin-top: -18vh;
      }
    }
  }

  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.5s;
      }
    }
    .images-wrap {
      .img-item {
        transform: translateY(0);
        transition: transform 0.7s;
        &:nth-of-type(1) {
          transition-delay: 0.2s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(3) {
          transition-delay: 0.6s;
        }
        &:nth-of-type(4) {
          transition-delay: 0.7s;
        }
      }
    }
  }
  ::v-deep .slide-image {
    height: 90%;
    width: auto !important;
  }
}
</style>
