<template>
  <div class="new-wood">
    <Tabs :active.sync="active" class="tabs"></Tabs>
    <transition enter-active-class="fadeIn animated">
      <!-- 新木团队 -->
      <Tab1 v-if="active == 3"></Tab1>
      <!-- 大事件 -->
      <Tab2 v-if="active == 2"></Tab2>
      <!-- 案例展示 -->
      <Tab3 v-if="active == 1" :lists="caseList"></Tab3>
      <!-- 加入我们 -->
      <Tab4 v-if="active == 4" :lists="jobList"></Tab4>
      <!-- 联系我们 -->
      <Tab5 v-if="active == 5"></Tab5>
      <!-- 业务介绍 -->
      <Tab6 v-if="active == 0"></Tab6>
    </transition>
  </div>
</template>

<script>
import Tabs from "./components/Tabs.vue";
import Tab1 from "./components/Tab-1.vue";
import Tab2 from "./components/Tab-2.vue";
import Tab3 from "./components/Tab-3.vue";
import Tab4 from "./components/Tab-4.vue";
import Tab5 from "./components/Tab-5.vue";
import Tab6 from "./components/Tab-6.vue";
import { getInfo, getJob } from "@/api";
export default {
  components: {
    Tabs,
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
  },
  data() {
    return {
      active: 0,
      listQuery: {
        pageSize: 9999,
        pageNum: 1,
        company: "XINMU",
        busiType: "ALZS",
      },
      caseList: [],
      jobList: [],
    };
  },
  methods: {
    changeTab(index) {
      this.active = index;
    },
    // 案例展示
    getInfo() {
      getInfo(this.listQuery).then((result) => {
        const { content } = result;
        this.caseList = content;
      });
    },
    // 岗位
    getJob() {
      getJob(this.listQuery).then((result) => {
        const { content } = result;
        this.jobList = content;
      });
    },
    handleScroll(e) {
      const _this = this;
      function fn() {
        let active = _this.active;
        let direction = e.deltaY > 0 ? "down" : "up";
        if (direction == "down") {
          active < 5 && active++;
        } else {
          active > 0 && active--;
        }
        _this.active = active;
      }
      if (!this.timer) {
        fn();
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
        }, 1000);
      }
    },
    handleOpenMousewheel() {
      window.addEventListener("mousewheel", this.handleScroll);
    },
    handleCloseMousewheel() {
      window.removeEventListener("mousewheel", this.handleScroll);
    },
  },
  created() {
    this.getInfo();
    this.getJob();
  },
  beforeDestroy() {
    this.handleCloseMousewheel();
  },
  mounted() {
    this.handleOpenMousewheel();
  },
};
</script>

<style lang="scss" scoped>
.new-wood {
  height: 100%;
  overflow: hidden;
  background: url("./imgs/bg@2x.png") center/100% 100% no-repeat;
  .tabs {
    position: fixed;
    left: 0;
    z-index: 88;
    top: -20px;
  }
}
</style>
