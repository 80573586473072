<template>
  <div :class="['tab-two', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-1.png" alt="" />
      <img class="background-2" src="../imgs/leaf-2.png" alt="" />
      <img class="background-3" src="../imgs/bg3.jpg" alt="" />
    </section>
    <section class="time-axis">
      <div class="line">
        <img class="xinmu" src="../imgs/xinmu.png" alt="" />
        <div class="circular">
          <section class="time-intro">
            <div class="year">{{ axisInfos[active]["year"] }}</div>
            <div class="desc">{{ axisInfos[active]["desc"] }}</div>
            <img class="pic" :src="axisInfos[active].url" alt="" />
          </section>
        </div>
        <ul :class="['times']">
          <div :class="['year', 'year-' + active]">
            <li :class="[active == index && 'active']" v-for="(item, index) in axisInfos" :key="index" @click="change(index)">
              {{ item.year }}
            </li>
          </div>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      axisInfos: [
        {
          year: "2018年4月",
          desc: "在集合了一批优质成员后，上海新木创意团队终于在2018年4月顺利诞生。",
          url: require("../imgs/ev-1.png"),
        },
        {
          year: "2019年5月",
          desc: "正式成为bilibili、阿里游戏、37互娱、波克城市、心动网络、完美世界、巨人等大厂的美术品类供应商。",
          url: require("../imgs/ev-2.png"),
        },
        {
          year: "2019年8月",
          desc: "新木创意团队有幸参与了《三国志幻想大陆》、《三国志战略版》等项目的2D视觉设计部分。",
          url: require("../imgs/ev-3.png"),
        },
        {
          year: "2020年6月",
          desc: "团队扩大至60人+，成为网易核心供应商。参与了《一梦江湖》、《黑潮之上》、《幻书启世录》、《第五人格》、《王牌竞速》、《率土之滨》、《天下》、《梦幻西游》等项目。",
          url: require("../imgs/ev-4.png"),
        },
        {
          year: "2021年6月",
          desc: "新木创意团队成员超100人。囊括了原画、设定、动画、界面、动特效、宣传美术等2D美术全品类。还顺利成为腾讯、西山居、字节跳动供应商。",
          url: require("../imgs/ev-5.png"),
        },
      ],
      active: 4,
      animate: false,
    };
  },
  methods: {
    change(index) {
      this.active = index;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-two {
  height: 100vh;
  .background {
    &-1 {
      position: fixed;
      top: remPc(-30);
      left: remPc(620);
      height: 40vh;
      z-index: 12;
      transform: rotate(-90deg);
      transform-origin: -200% 120%;
    }
    &-2 {
      height: 40vh;
      position: fixed;
      top: remPc(-100);
      transform: translateY(-100%);
      left: remPc(651);
      z-index: 11;
    }
    &-3 {
      height: 30vh;
      position: fixed;
      top: remPc(10);
      transform: translateY(-100%);
      left: remPc(1050);
      z-index: 11;
    }
  }
  .time-axis {
    .line {
      position: fixed;
      left: remPc(1210);
      transform: rotate(6deg);
      bottom: 0;
      width: 3px;
      height: 120vh;
      background: transparent;
      z-index: 5;
      &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 0;
        background: $color;
        left: 0;
        bottom: 0;
      }
      .circular {
        position: absolute;
        left: 50%;
        bottom: remPc(400);
        transform: translateX(-50%);
        width: remPc(35);
        height: remPc(35);
        background: #facb2b;
        border-radius: 50%;
        z-index: 11;
        opacity: 0;
        .time-intro {
          position: absolute;
          left: remPc(100);
          bottom: remPc(0);
          transform: translateY(73%) rotate(-16deg);
          width: remPc(554);
          height: remPc(515);
          .year {
            color: #00d6e3;
            font-size: remPc(40);
            transform: rotate(-90deg);
            transform-origin: 200% center;
            font-weight: bold;
            opacity: 1;
          }
          .desc {
            margin-top: remPc(100);
            margin-left: remPc(-40);
            font-size: remPc(18);
            letter-spacing: 2px;
            line-height: remPc(36);
            width: remPc(500);
            font-weight: 600;
            transform: rotate(-90deg);
            transform-origin: 200% center;
            color: #666;
            opacity: 1;
          }
          .pic {
            width: remPc(391);
            margin: remPc(-50) 0 0 remPc(-20);
            opacity: 0;
            transform: rotate(11deg) translateX(200%);
          }
        }
      }
      .xinmu {
        position: absolute;
        width: remPc(306);
        top: 54vh;
        left: remPc(-300);
        z-index: 3;
        opacity: 0;
        transform: rotate(-6deg);
      }
      .times {
        position: absolute;
        left: remPc(-190);
        width: remPc(140);
        bottom: remPc(35);
        cursor: pointer;
        transition: transform 1s;
        padding: remPc(30);
        mask: -webkit-gradient(linear, left center, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
        height: remPc(600);
        .year {
          &.year-0 {
            transform: rotate(0deg) translateY(remPc(250));
          }
          &.year-1 {
            transform: rotate(0deg) translateY(remPc(200));
          }
          &.year-2 {
            transform: rotate(0deg) translateY(remPc(150));
          }
          &.year-3 {
            transform: rotate(0deg) translateY(remPc(100));
          }
          &.year-4 {
            transform: rotate(0deg) translateY(remPc(50));
          }
          &.year-5 {
            transform: rotate(0deg) translateY(remPc(0));
          }
          li {
            font-size: remPc(19);
            color: #888;
            font-weight: 600;
            line-height: remPc(50);
            text-align: right;
            opacity: 0;
            transform: translate(remPc(10), remPc(20)) rotate(-20deg);
            &.active {
              visibility: hidden;
            }
            &:hover {
              color: #00d6e3;
            }
          }
        }
      }
    }
  }

  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.5s 0s;
      }
      &-2 {
        transform: translateY(0%);
        transition: transform 0.7s 0.2s;
      }
      &-3 {
        transform: translateY(0%);
        transition: transform 0.2s;
      }
    }
    .time-axis {
      .line {
        &::before {
          height: 120vh;
          transition: height 1s 0.5s;
        }
        .circular {
          opacity: 1;
          transition: opacity 1s 0.9s;
          .time-intro {
            .year {
              transform: rotate(0);
              transition: transform 0.7s 0.9s;
            }
            .desc {
              transform: rotate(5deg);
              transition: transform 0.7s 1s;
            }
            .pic {
              opacity: 1;
              transform: rotate(11deg) translateX(remPc(-30));
              transition: opacity 1s 1.6s, transform 1s 1s;
            }
          }
        }
        .xinmu {
          opacity: 1;
          transition: opacity 2s 1s;
        }
        .times {
          .year {
            transition: transform 0.5s;
            li {
              transition: transform 1s, opacity 1s;
              opacity: 1;
              transform: translate(remPc(10), remPc(0)) rotate(-20deg);
              &:nth-of-type(1) {
                transition-delay: 0.8s;
              }
              &:nth-of-type(2) {
                transition-delay: 0.9s;
              }
              &:nth-of-type(3) {
                transition-delay: 1s;
              }
              &:nth-of-type(4) {
                transition-delay: 1.1s;
              }
              &:nth-of-type(5) {
                transition-delay: 1.15s;
              }
              &:nth-of-type(6) {
                transition-delay: 1.2s;
              }
              &:nth-of-type(7) {
                transition-delay: 1.25s;
              }
            }
          }
        }
      }
    }
  }
}
</style>
