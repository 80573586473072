<template>
  <div :class="['tab-four', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-5.png" alt="" />
      <img class="background-2" src="../imgs/leaf-6.png" alt="" />
      <div class="background-3">新木创意</div>
      <img class="background-4" src="../imgs/contactus.png" alt="" />
    </section>
    <div class="words-wrap">
      <p class="words">新木创意，专注于提供游戏产业的设计研发服务工作。成员来自行业各个公司一线团队。</p>
      <p class="words">
        通过与教育板块的协作，新木网络已具备人员培养、业务拓展，产品升级等一系列商业能力。并借助线上合作，持
        续发挥着自身专业优势。
      </p>
    </div>
    <div class="welfare">
      <img @click="visible = true" src="../imgs/fl.png" alt="" />
      <p>快乐的年会拾光~充满激情的讨论中~</p>
    </div>
    <div class="recruit">
      <div class="wrap">
        <div class="wrap-item" v-for="(item, index) in lists" :key="index">
          <p>{{ item.name }}</p>
          <p>共{{ item.num }}个职位</p>
        </div>
      </div>
    </div>
    <transition name="bounce">
      <Popup :visible.sync="visible" v-if="visible" :info="popupInfo"></Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./Popup";
export default {
  components: {
    Popup,
  },
  props: ["lists"],
  data() {
    return {
      animate: false,
      visible: false,
      popupInfo: {
        files: [
          { url: require("../imgs/fl.png") },
          { url: require("../imgs/fl-2.png") },
          { url: require("../imgs/fl-3.png") },
          { url: require("../imgs/fl-4.png") },
          { url: require("../imgs/fl-5.png") },
        ],
        title: "年会照片",
        desc: "欢乐的时光总是值得留恋，新木周年庆进行中</br>每年新木都会进行一次全体成员参与的年会互动，游戏美食、惊喜抽奖应有尽有。",
      },
    };
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-four {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .words-wrap {
    position: absolute;
    left: remPc(364);
    top: remPc(155);
    .words {
      font-size: remPc(18);
      color: #000000;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: remPc(30);
      transform: translateY(20px);
      opacity: 0;
      cursor: pointer;
      &:nth-of-type(1) {
        width: remPc(440);
      }
      &:nth-of-type(2) {
        margin-top: remPc(50);
        width: remPc(490);
        transform: rotate(-5deg) translateX(-200px);
      }
    }
  }
  .welfare {
    position: absolute;
    bottom: remPc(80);
    left: remPc(200);
    opacity: 0;
    transform: translateY(150%);
    cursor: pointer;
    img {
      height: 44vh;
      transition: transform 0.5s;
      // &:hover {
      //   transform: scale(1.05);
      // }
    }
    p {
      position: absolute;
      bottom: 0;
      font-size: remPc(18);
      width: 100%;
      text-indent: remPc(120);
    }
  }
  .background {
    &-1 {
      height: 41vh;
      position: absolute;
      top: remPc(-100);
      right: remPc(-100);
      transform: rotate(90deg);
      transform-origin: 200% center;
    }
    &-2 {
      height: 52vh;
      position: absolute;
      top: remPc(-100);
      right: remPc(-100);
      z-index: 3;
      transform: rotate(90deg);
      transform-origin: 200% center;
    }
    &-3 {
      font-size: remPc(80);
      position: absolute;
      top: 38vh;
      right: remPc(580);
      color: #fac82b;
      z-index: 2;
      font-weight: bold;
      opacity: 0;
    }
    &-4 {
      height: 21vh;
      position: absolute;
      right: 0;
      bottom: 3vh;
      opacity: 0;
      transform: scale(1.2);
    }
  }
  .recruit {
    width: 50vw;
    padding: 0 remPc(50);
    position: absolute;
    height: remPc(80);
    // border-top: 4px solid #fac82b;
    right: remPc(-100);
    top: 44vh;
    transform: rotate(-10deg);
    z-index: 10;

    &::before {
      content: "招聘职位";
      font-size: remPc(19);
      color: $color;
      position: absolute;
      left: 6px;
      height: remPc(30);
      font-weight: bold;
      top: remPc(-30);
      opacity: 0;
    }
    &::after {
      content: "";
      background: #fac82b;
      position: absolute;
      height: 4px;
      width: 0;
      top: 0;
      right: 0;
    }
    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      opacity: 0;
      cursor: pointer;
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
        // transform: rotate(5deg);
        p {
          &:nth-of-type(1) {
            font-size: remPc(20);
            color: #3d3d3d;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            font-size: remPc(17);
          }
        }
      }
    }
  }
  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.4s 0.2s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.4s;
        // &:hover {
        //   transform: rotate(1deg);
        // }
      }
      &-3 {
        opacity: 1;
        transition: opacity 1s 1s;
      }
      &-4 {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s 1.2s, transform 0.4s 1.2s;
      }
    }
    .recruit {
      transition: transform 0.4s;
      // &:hover {
      //   transform: rotate(-12deg);
      // }
      &::after {
        width: 100%;
        transition: width 0.5s 0.5s;
      }
      &::before {
        opacity: 1;
        transition: opacity 0.5s 1s;
      }
      .wrap {
        opacity: 1;
        transform: translateX(0);
        transition: transform 0.7s 1.2s, opacity 0.7s 1.2s;
      }
    }
    .words-wrap {
      .words {
        transform: translateY(0);
        opacity: 1;
        &:nth-of-type(1) {
          transition: transform 0.5s 0.8s, opacity 0.5s 0.8s;
          // &:hover {
          //   font-weight: bold;
          //   color: $color;
          // }
        }
        &:nth-of-type(2) {
          transform: rotate(-5deg) translateX(0px);
          transition: transform 0.5s 1s, opacity 0.5s 1s;
          // &:hover {
          //   font-weight: bold;
          //   color: $color;
          // }
        }
      }
    }
    .welfare {
      transform: translateY(0);
      opacity: 1;
      transition: opacity 2s 0.3s, transform 0.5s 0.5s;
    }
  }
}
</style>
