<template>
  <div :class="['tab-one', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/bg1.png" alt="" />
      <img class="background-2" src="../imgs/bg2.png" alt="" />
    </section>
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="slide" v-for="(item, index) in photos" :key="index">
          <img class="photo" :src="item.url" alt="" />
        </swiper-slide>
      </swiper>
      <div class="swiper-arrow">
        <img class="item" @click="slidePrev" src="../imgs/l-2.png" alt="" />
        <img class="item" @click="slideNext" src="../imgs/r-2.png" alt="" />
      </div>
    </section>
    <section class="swiper-small">
      <div
        :class="['photo-wrap']"
        :style="{
          transform: `translateY(${-10 * (activeIndex - 1)}vh)`,
        }"
      >
        <img
          :class="['photo', activeIndex - 1 == index && 'active']"
          :src="item.smUrl"
          alt=""
          v-for="(item, index) in photosAdd"
          @click="slideTo(index)"
          :key="index"
        />
      </div>
    </section>
    <section class="swiper-intro">
      <div class="name-wrap">
        <div class="name">{{ photos[activeIndex - 1]["name"] }}</div>
        <div class="work">{{ photos[activeIndex - 1]["work"] }}</div>
        <img class="leader" src="../imgs/leader.png" alt="" />
      </div>
      <div class="desc" v-html="photos[activeIndex - 1]['desc']"></div>
    </section>
    <div class="lines">
      <div class="lines-1 item"></div>
      <div class="lines-2 item"></div>
      <div class="lines-3 item"></div>
      <!-- <div class="lines-4"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      photos: [
        {
          url: require("../imgs/2D组.png"),
          smUrl: require("../imgs/2D头像.png"),
          name: "2D组",
          work: "新木2D组成员",
          desc: `
          <p>负责任人： 豹豹，做一个有爱、有战斗力的团队</p>
          <p>工作涵盖： 图标、原画、美宣</p>
          <p>参与项目：《原神》《大富翁》《QQ飞车》《魂斗罗》《一拳超人》《最强蜗牛》《一梦江湖》《斗罗大陆》等</p>
          `,
        },
        {
          url: require("../imgs/3D组.png"),
          smUrl: require("../imgs/3D头像.png"),
          name: "3D组",
          work: "新木3D组成员",
          desc: `
          <p>工作涵盖： 场景次世代、动作、3渲2、特效、买量视频</p>
          <p>参与项目：《王牌竞速》《QQ飞车》《拳皇》《铁甲雄兵》《神舞幻想2》等</p>
          `,
        },
        {
          url: require("../imgs/UI组.png"),
          smUrl: require("../imgs/UI头像.png"),
          name: "UI组",
          work: "新木UI组成员",
          desc: `
          <p>工作涵盖： UI风格设定、Banner设计、UI动效</p>
          <p>参与项目：《英雄联盟》《花亦山心之月》《梦幻西游》《武林闲侠》《荒野行动》《蛋仔派对》《非人学园》《黑潮之上》《幻书启世录》等</p>
          `,
        },
      ],
      swiperOptions: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 6000,
        },
        loop: true,
        allowTouchMove: false,
        speed: 100,
        pagination: {
          el: ".swiper-pagination",
        },
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.realIndex + 1;
          },
        },
      },
      animate: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    photosAdd() {
      return [this.photos[this.photos.length - 1], ...this.photos, this.photos[0], this.photos[0]];
    },
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-one {
  height: 100%;
  overflow: hidden;
  position: relative;
  .background {
    &-1 {
      position: fixed;
      bottom: remPc(-40);
      left: remPc(-54);
      width: remPc(1041);
      transform: rotate(-90deg);
      transform-origin: -20% 80%;
    }
    &-2 {
      width: remPc(1680);
      position: fixed;
      bottom: remPc(-520);
      left: remPc(50);
      transform: translateY(100%);
    }
  }
  .swiper-wrap {
    width: 80vh;
    height: 90vh;
    bottom: 0;
    position: absolute;
    left: remPc(314);
    opacity: 0;
    .slide {
      .photo {
        height: 100%;
        width: 100%;
      }
    }
    .swiper-arrow {
      width: 110%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 10;
      top: 30vh;
      opacity: 0;
      left: -5%;
      .item {
        cursor: pointer;
        width: remPc(56);
      }
    }
  }
  .swiper-small {
    position: fixed;
    right: remPc(50);
    top: 50vh;
    opacity: 1;
    width: remPc(80);
    z-index: 99;
    transform: translateY(-300%);
    height: 30vh;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      // box-shadow: 0px 1.5vh 2.5vh 2.5vh #f2f3f6;
      position: absolute;
      top: 0px;
      z-index: 10;
    }
    &::after {
      content: "";
      width: 100%;

      // box-shadow: 0px -1.5vh 2.5vh 2.5vh#f2f3f6;
      position: absolute;
      bottom: 0px;
      z-index: 10;
    }
    .photo-wrap {
      transition: transform 0.5s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      z-index: -2;
      .photo {
        width: 100%;
        height: 7vh;
        margin: 1.5vh 0;
        cursor: pointer;
      }
    }
  }
  .swiper-intro {
    position: fixed;
    bottom: remPc(256);
    left: remPc(1250);
    width: remPc(600);
    height: remPc(400);
    .name-wrap {
      width: remPc(241);
      height: remPc(274);
      background: url("../imgs/line.png") center/100% no-repeat;
      opacity: 0;
      transform: scale(1.7);
      .name {
        font-size: remPc(66);
        font-weight: bold;
        transform: rotate(-5deg) translate(remPc(-20), remPc(80));
      }
      .work {
        font-size: remPc(20);
        color: #999;
        letter-spacing: 2px;
        transform: rotate(-20deg) translate(remPc(-70), remPc(70));
      }
      .leader {
        width: remPc(124);
        transform: translateX(remPc(130));
      }
    }
    .desc {
      position: absolute;
      width: remPc(450);
      font-size: remPc(18);
      height: 7vh;
      color: #666;
      line-height: remPc(36);
      font-weight: 600;
      left: remPc(50);
      bottom: remPc(40);
      opacity: 0;
      transform: translateY(50px);
    }
  }
  .lines {
    &-1 {
      bottom: -5px;
      transform: rotate(-10deg);
      right: remPc(120);
      height: remPc(130);
    }
    &-2 {
      bottom: -5px;
      transform: rotate(7deg);
      right: remPc(20);
      height: remPc(450);
    }

    &-3 {
      bottom: -5px;
      transform: rotate(-10deg);
      left: remPc(60);
      height: remPc(430);
    }
    .item {
      position: absolute;
      width: 3px;
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        background: $color;
        width: 100%;
        height: 0;
        bottom: 0;
      }
    }
  }
  &.animate {
    .background {
      &-1 {
        transform: rotate(0);
        transition: transform 0.4s 0.2s;
      }
      &-2 {
        transform: translateY(0);
        transition: transform 0.4s;
      }
    }
    .swiper-wrap {
      opacity: 1;
      transition: opacity 0.6s 1s;
      .swiper-arrow {
        opacity: 1;
        transition: opacity 1s 1.3s;
      }
    }
    .swiper-small {
      transform: translateY(-50%);
      transition: transform 1s 0.4s;
    }
    .swiper-intro {
      .name-wrap {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.1s 1.5s, transform 0.2s 1.5s;
      }
      .desc {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 0.7s 1.2s, transform 0.7s 1.2s;
      }
    }
    .lines {
      .item {
        &::after {
          transition: height 1s 1.2s;
          height: 100%;
        }
      }
    }
  }
}
</style>
