<template>
  <div :class="['tab-five', animate && 'animate']">
    <section class="background">
      <img class="background-1" src="../imgs/leaf-7.png" alt="" />
      <img class="background-2" src="../imgs/leaf-8.png" alt="" />
      <div class="line"></div>
    </section>
    <div class="pic">
      <img class="xmcy" src="../imgs/xmcy.png" alt="" />
      <img class="conpany" src="../imgs/company.png" alt="" />
    </div>
    <div class="contact">
      <p>团队地址：上海市杨浦区大桥街道长阳路1687号长阳创谷1号楼1单元410室</p>
      <p>商务信箱：BD@leafstudio.cn</p>
      <p>商务电话：17301823445、17301649409</p>
      <p>招聘邮箱：HR@leafstudio.cn</p>
      <p class="wechat"><span>微信联系：</span><img src="../imgs/wechat.png" alt="" /></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animate: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
.tab-five {
  height: 100vh;
  position: relative;
  .background {
    position: absolute;
    width: remPc(963);
    height: remPc(419);
    right: remPc(111);
    &-1 {
      position: absolute;
      height: 38vh;
      left: 0;
      width: 100%;
      height: 100%;
      top: remPc(-20);
      transform: translateY(-100%);
    }
    &-2 {
      position: absolute;
      height: 47vh;
      top: remPc(20);
      left: remPc(-84);
      z-index: 2;
      transform: rotate(90deg);
      transform-origin: 200% 150%;
    }
    .line {
      width: remPc(1100);
      height: 4px;
      transform: rotate(22deg);
      position: absolute;
      top: remPc(480);
      left: remPc(280);
      &::after {
        content: "";
        display: inline-block;
        background: #fac82b;
        width: 0;
        height: 100%;
        position: absolute;
        right: 0;
      }
    }
  }
  .pic {
    position: absolute;
    top: remPc(220);
    right: remPc(1210);
    z-index: 3;
    .xmcy {
      position: absolute;
      width: remPc(255);
      opacity: 0;
    }
    .conpany {
      height: 35vh;
      position: absolute;
      top: remPc(280);
      transform: translateX(-20%) rotate(-90deg);
      transform-origin: -200% 50%;
      z-index: 4;
    }
  }
  .contact {
    position: absolute;
    bottom: 18vh;
    right: remPc(266);
    p {
      transform: translateY(20px);
      opacity: 0;
      font-size: remPc(20);
      font-weight: bold;
      line-height: remPc(40);
    }
  }
  &.animate {
    .background {
      &-1 {
        transform: translateY(0);
        transition: transform 0.7s 0.2s;
      }
      &-2 {
        transform: rotate(0);
        transition: transform 0.5s;
      }
      .line {
        &::after {
          width: 100%;
          transition: width 1s 0.7s;
        }
      }
    }
    .pic {
      .xmcy {
        opacity: 1;
        transition: opacity 2s 1.5s;
      }
      .conpany {
        transform: translateX(-20%) rotate(-10deg);
        transition: transform 1s 0.5s;
      }
    }
    .contact {
      p {
        transform: translateY(0px);
        opacity: 1;
        &:nth-of-type(1) {
          transition: all 0.8s 1.1s;
        }
        &:nth-of-type(2) {
          transition: all 0.8s 1.3s;
        }
        &:nth-of-type(3) {
          transition: all 0.8s 1.5s;
        }
        &:nth-of-type(4) {
          transition: all 0.8s 1.7s;
        }
        &:nth-of-type(5) {
          transition: all 0.8s 1.9s;
        }
      }
      .wechat {
        span {
          float: left;
        }
        img {
          width: remPc(80);
          float: left;
        }
      }
    }
  }
}
</style>
