<template>
  <div class="popup">
    <div class="background" v-if="showChange">
      <img src="../imgs/pop-dec.png" alt="" @click="changeInfo(-1)" v-show="popupIndex > 0" />
      <img src="../imgs/pop-dec.png" alt="" @click="changeInfo(1)" v-show="popupIndex < listLenght - 1" />
    </div>
    <div class="popup-l">
      <section class="swiper-wrap">
        <swiper ref="popupSwiper" :options="popupOptions">
          <swiper-slide class="slide" v-for="(item, index) in info.files" :key="index">
            <img class="slide-image" :src="item.url" alt="" />
          </swiper-slide>
        </swiper>
      </section>
      <div class="paging">
        <img class="arrow" @click="slidePrev('popup')" src="../imgs/r-1.png" alt="" />
        <span class="number">{{ activeIndex }}</span>
        <img class="arrow" @click="slideNext('popup')" src="../imgs/l-1.png" alt="" />
      </div>
    </div>
    <div class="popup-r">
      <h2>{{ info.title }}</h2>
      <div class="zx">
        <img src="../imgs/zx.png" alt="" />
      </div>
      <div class="desc" v-html="info.desc"></div>
    </div>
    <div class="close" @click="closePopup">
      <img src="../imgs/close.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
    info: {
      type: Object,
      default: {
        files: [],
        title: "",
        desc: "",
      },
    },
    showChange: {
      type: Boolean,
      default: false,
    },
    popupIndex: {
      type: Number,
    },
    listLenght: {
      type: Number,
    },
  },
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      popupOptions: {
        allowTouchMove: false,
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
        },
      },
    };
  },
  computed: {
    popupSwiper() {
      return this.$refs.popupSwiper.$swiper;
    },
  },
  methods: {
    slidePrev(type) {
      this["popupSwiper"].slidePrev();
    },
    slideNext(type) {
      this["popupSwiper"].slideNext();
    },
    closePopup() {
      this.$emit("update:visible", false);
    },
    changeInfo(n) {
      this["popupSwiper"].slideTo(0, 0, false);
      this.$emit("change-index", n);
    },
  },
  mounted() {
    this.$parent.$parent.handleCloseMousewheel();
  },
  beforeDestroy() {
    this.$parent.$parent.handleOpenMousewheel();
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.popup {
  z-index: 99;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.9);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .background {
    img {
      position: absolute;
      height: 28vh;
      transform: translateY(-50%);
      top: 45%;
      cursor: pointer;

      &:nth-of-type(1) {
        left: 0;
        transform: translate(-80%, -50%);
      }
      &:nth-of-type(2) {
        right: 0;
        transform: translate(80%, -50%);
      }
    }
  }
  &-l {
    height: 65vh;
    width: 45vw;
    padding-left: 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .swiper-wrap {
      width: remPc(688);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .swiper-container {
        width: 100%;
        .slide {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          box-sizing: border-box;
          img {
            width: 100%;
          }
        }
      }
    }
    .paging {
      width: remPc(370);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        width: remPc(20);
        height: remPc(40);
        cursor: pointer;
      }
      .number {
        font-size: remPc(20);
        font-weight: bold;
        color: $color;

        letter-spacing: 2px;
      }
    }
  }
  &-r {
    height: 60vh;
    width: 55vw;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6vw 3vw;
    h2 {
      font-size: remPc(50);
      font-weight: 800;
    }
    .zx {
      margin: remPc(20) 0 remPc(40) 0;
      transform: translateX(-20px);
      img {
        width: remPc(800);
      }
    }
    .desc {
      font-size: remPc(20);
      line-height: remPc(50);
      width: remPc(700);
      letter-spacing: 2px;
    }
  }
  .close {
    position: absolute;
    right: remPc(228);
    top: remPc(107);
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: transform 1s;
    }
    img {
      width: remPc(44);
    }
  }
}
</style>
