<template>
  <div :class="['tab-three', animate && 'animate']">
    <section class="background">
      <img src="../imgs/leaf-9.png" alt="" />
    </section>
    <div class="lists-wrap">
      <div class="lists">
        <section class="swiper-wrap">
          <swiper ref="pageSwiper" :options="pageOptions">
            <swiper-slide :class="['slide', index == 0 && 'first']" v-for="(item, index) in groupLists" :key="index">
              <ul>
                <li class="lists-item" v-for="(_item, _index) in item" :key="_index" @click="openPopup(_item, index, _index)">
                  <span class="index">{{ _index | indexF(activeIndex) }}</span>
                  <img class="pic" :src="_item.files[0].url" alt="" />
                  <img class="bg" src="../imgs/bg4.png" alt="" />
                  <p class="title">{{ _item.title }}</p>
                </li>
              </ul>
            </swiper-slide>
          </swiper>
        </section>
      </div>
      <div class="paging">
        <img class="arrow" @click="slidePrev('page')" src="../imgs/r-1.png" alt="" />
        <span class="number">第{{ activeIndex }}页</span>
        <img class="arrow" @click="slideNext('page')" src="../imgs/l-1.png" alt="" />
      </div>
    </div>
    <transition name="bounce">
      <Popup
        :visible.sync="visible"
        :info="popupInfo"
        :showChange="true"
        :popupIndex="popupIndex"
        :listLenght="lists.length"
        @change-index="changePopupIndex"
        v-if="visible"
      ></Popup>
    </transition>
  </div>
</template>

<script>
import Popup from "./Popup";
export default {
  components: {
    Popup,
  },
  props: ["lists"],
  data() {
    const _this = this;
    return {
      animate: false,
      activeIndex: 1,
      pageOptions: {
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
        },
      },
      visible: false,
      popupIndex: 0,
    };
  },
  filters: {
    indexF(value, activeIndex) {
      const temp = (activeIndex - 1) * 6 + value + 1;
      return temp >= 10 ? temp : "0" + temp;
    },
  },
  computed: {
    groupLists() {
      return this.group(this.lists, 6);
    },
    pageSwiper() {
      return this.$refs.pageSwiper.$swiper;
    },
    popupInfo() {
      return this.lists[this.popupIndex];
    },
  },
  methods: {
    group(array, subGroupLength) {
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    slidePrev(type) {
      this["pageSwiper"].slidePrev();
    },
    slideNext(type) {
      this["pageSwiper"].slideNext();
    },
    openPopup(info, index, index_) {
      this.visible = true;
      this.popupIndex = index * 6 + index_;
    },
    changePopupIndex(index) {
      this.popupIndex += index;
    },
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 22);
  },
};
</script>

<style lang="scss" scoped>
$color: #8a53ec;
.tab-three {
  height: 100vh;
  overflow: hidden;
  position: relative;
  .background {
    position: absolute;
    bottom: remPc(-100);
    right: remPc(-80);
    transform: rotate(-50deg);
    transform-origin: 200%;
    img {
      height: 32vh;
    }
  }
  .lists-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100vh;
    padding-left: 5vw;
    .swiper-wrap {
      width: 100%;
      height: 100%;
    }
    .lists {
      width: 125vh;
      height: 75vh;
      .first {
        .lists-item {
          &:nth-of-type(1) {
            transform: translateX(350%);
          }
          &:nth-of-type(2) {
            transform: translateX(250%);
          }
          &:nth-of-type(3) {
            transform: translateX(150%);
          }
          &:nth-of-type(4) {
            transform: translateX(-150%);
          }
          &:nth-of-type(5) {
            transform: translateX(-250%);
          }
          &:nth-of-type(6) {
            transform: translateX(-350%);
          }
        }
      }
      &-item {
        width: 35vh;
        height: 30vh;
        float: left;
        margin-left: 5vh;
        margin-bottom: 3vh;
        position: relative;
        padding-top: 6vh;
        cursor: pointer;
        .index {
          font-size: remPc(100);
          position: absolute;
          top: -10px;
          left: 50%;
          line-height: 10vh;
          color: #fac82b;
          transform: translateX(-50%);
          z-index: -1;
          font-weight: bold;
        }
        .pic {
          width: 100%;
          position: absolute;
          height: auto;
          top: 20px;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.1);
          }
        }
        .bg {
          position: absolute;
          bottom: 0;
          width: 52%;
          left: 50%;
          transform: translateX(-49%);
        }
        .title {
          position: absolute;
          bottom: 5px;
          text-align: center;
          width: 100%;
          font-size: remPc(18);
          font-weight: bold;
        }
      }
    }
    .paging {
      width: remPc(370);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        width: remPc(24);
        cursor: pointer;
        opacity: 0;
      }
      .number {
        opacity: 0;
        font-size: remPc(20);
        font-weight: bold;
        letter-spacing: 2px;
      }
    }
  }
  &.animate {
    .background {
      transform: rotate(0);
      transition: transform 0.5s;
    }
    .lists-wrap {
      .lists {
        .first {
          .lists-item {
            transform: translateX(0);
            &:nth-of-type(1) {
              transition: transform 0.9s 0.1s;
            }
            &:nth-of-type(2) {
              transition: transform 0.7s 0.3s;
            }
            &:nth-of-type(3) {
              transition: transform 0.5s 0.5s;
            }
            &:nth-of-type(6) {
              transition: transform 0.9s 0.7s;
            }
            &:nth-of-type(5) {
              transition: transform 0.7s 0.9s;
            }
            &:nth-of-type(4) {
              transition: transform 0.5s 1.1s;
            }
          }
        }
      }
      .paging {
        .arrow {
          opacity: 1;
          transition: opacity 1s 1.8s;
        }
        .number {
          opacity: 1;
          transition: opacity 2s 1.2s;
        }
      }
    }
  }
}
</style>
